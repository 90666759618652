import { Box, Button, TextField, Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../store/reducers/company";
import { DropDownBox } from "devextreme-react";
import { getFeedBackTypeList, setFilter } from "../../store/reducers/feedBack";
import moment from "moment";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const gridBoxDisplayExpr2 = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const logedUser = useSelector((state) => state.auth.user);
  const [feedBackTypeGridBoxValue, setFeedBackTypeGridBoxValue] = useState(0);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isGridBoxOpenedType, setIsGridBoxOpenedType] = useState(false);
  const feedBackTypeList = useSelector(
    (state) => state.feedBack.feedBackTypeList
  );
  const [formData, setFormData] = useState([]);
  const [feedBackType, setFeedBackType] = useState([]);
  const gridColumns = ["name"];
  const filter = useSelector((state) => state.company.filter);
  const [typeDate, setTypeDate] = useState([
    { id: null, name: "Бүгд" },
    {
      id: 1,
      name: "Утсаар",
    },
    { id: 3, name: "Вэб сайтаар" },
  ]);
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log(startDate);
    if (startDate === null) {
      const offsetStart = new Date(
        moment().year() + "-" + moment().month() + "-01"
      ).getTimezoneOffset();

      const startDateValue = new Date(
        new Date(moment().year() + "-" + moment().month() + "-01").getTime() -
          offsetStart * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      setStartDate(startDateValue);
    } else if (endDate === null) {
      const offsetEnd = new Date(
        moment().year() + "-" + "12" + "-31"
      ).getTimezoneOffset();

      const endDateValue = new Date(
        new Date(moment().year() + "-" + "12" + "-31").getTime() -
          offsetEnd * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      setEndDate(endDateValue);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (feedBackTypeList == null) {
      dispatch(getFeedBackTypeList());
    } else {
      // var data = [];
      // feedBackTypeList.map((e) => {
      //   data.push(e.child);
      // });

      setFeedBackType(feedBackTypeList);
    }
  }, [feedBackTypeList, dispatch]);
  const dateFilterFun = (value, type) => {
    // console.log(type);

    if (type == 1) {
      const offsetStart = value.getTimezoneOffset();
      value = new Date(value.getTime() - offsetStart * 60 * 1000);

      const startDateValue = value.toISOString().split("T")[0];
      //  console.log(endDate.toString());

      setStartDate(startDateValue);
      // props.getFilters({ start_date: startDateValue, end_date: endDate });
      dispatch(
        setFilter({
          ...filter,
          start_date: startDateValue,
          end_date: endDate,
        })
      );

      //  console.log(startDateValue);

      // props.getFilters("start_date", startDateValue);
      // props.getFilters("end_date", endDate);
    } else {
      // console.log(startDate);
      const offsetEnd = value.getTimezoneOffset();
      value = new Date(value.getTime() - offsetEnd * 60 * 1000);

      const endDateValue = value.toISOString().split("T")[0];
      setEndDate(endDateValue);

      // props.getFilters({ start_date: startDate, end_date: endDateValue });
      dispatch(
        setFilter({
          ...filter,
          start_date: startDate,
          end_date: endDateValue,
        })
      );

      // const offsetStart = startDate.getTimezoneOffset();

      // const startDateValue = new Date(
      //   startDate.getTime() - offsetStart * 60 * 1000
      // )
      //   .toISOString()
      //   .split("T")[0];
      // console.log(endDateValue);

      // props.getFilters("start_date", startDate);
      // props.getFilters("end_date", endDateValue);
    }
  };

  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={feedBackType}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={feedBackTypeGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setFeedBackTypeGridBoxValue(parseInt(selValue[0]));
          //  console.log(selValue[0]);
          setIsGridBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  const dataTypeRender = () => {
    return (
      <DataGrid
        dataSource={typeDate}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpenedType(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  return (
    <>
      <div style={{ margin: "10px" }} className="caption">
        Шүүлт
      </div>
      <Stack direction={"row"} spacing={1}>
        <Typography>Эх үүсвэр сонгох:</Typography>
        <DropDownBox
          height={30}
          value={typeDateGridBoxValue}
          opened={isGridBoxOpenedType}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr2}
          placeholder="Эх үүсвэр сонгох..."
          showClearButton={false}
          dataSource={typeDate}
          onValueChanged={(e) => {
            setTypeDateGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("source_id", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridBoxOpenedType(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataTypeRender}
        />

        <Typography>Төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          value={feedBackTypeGridBoxValue}
          opened={isGridBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Төрөл сонгох..."
          showClearButton={true}
          dataSource={feedBackType}
          onValueChanged={(e) => {
            setFeedBackTypeGridBoxValue(e.value);
            props.getFilters("type_id", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataCompNameGridRender}
        />
        <Typography>Үүсгэсэн огноо:</Typography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          spacing={1}
          sx={{ mt: 1 }}
        >
          <DateBox
            height={30}
            defaultValue={
              startDate === null
                ? new Date(moment().year() + "-" + moment().month() + "-01")
                : startDate
            }
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              setStartDate(e.value);
              dateFilterFun(e.value, 1);
              //  props.getFilters("start_date", e.value);
            }}
            type="date"
          />
          <HorizontalRule sx={{ fontSize: "12px", mt: 3 }} />
          <DateBox
            height={30}
            defaultValue={
              endDate === null
                ? new Date(moment().year() + "-" + "12" + "-31")
                : endDate
            }
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              setEndDate(e.value);
              dateFilterFun(e.value, 2);
              // props.getFilters("end_date", e.value);
            }}
            type="date"
          />
        </Stack>
        <Typography>Хайлт:</Typography>
        <TextBox
          // ref={this.CarTextRef}
          height={30}
          placeholder="Хайлт...."
          showClearButton={true}
          onValueChanged={(e) => {
            // console.log([...e.value].length);
            if ([...e.value].length > 0) {
              props.getFilters("search_value", e.value);
            } else {
              props.getFilters("search_value", null);
            }
          }}
          onKeyUp={(e) => {
            if (e.event.keyCode === 13) {
              e.event.preventDefault();
              //  console.log(e.event.target.value);
            }
            // console.log(e.event.target.value);
          }}
        />
      </Stack>
    </>
  );
};

export default FilterBox;
