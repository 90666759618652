import { Box, IconButton, Stack, Typography } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
//import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import {
  getFeedBack,
  getVehicleSearch,
  setVehicleSearchData,
  setFilter as sss,
} from "../../store/reducers/vehicle";
//import { Button as TextBoxButton } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import FilterBox from "./filter";
import { Popup as Modal } from "devextreme-react/popup";

import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  Form,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Texts,
  RequiredRule,
} from "devextreme-react/data-grid";

import Toolbar, { Item } from "devextreme-react/toolbar";

import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";
import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import { ButtonTypes } from "devextreme-react/button";

import {
  getCompanyTypeData,
  getTransportZoneAddEditFun,
  getTransportZoneFun,
  getUserTypeList,
  getVehicleSearchDataFun,
  getViolationCitizenTypeData,
  getViolationCompanyCrudFun,
  getViolationCompanyEditFun,
  getViolationDecisionTypeFun,
  getViolationDetectionTypeFun,
  getViolationLegalPenaltyFun,
  getViolationLegalProvisionFun,
  getViolationOffenderFun,
  getViolationTypeDataFun,
  getViolationVehicleCrud,
  getviolationServiceTypeCrudFun,
  userPositionTypeAddEditFun,
  userTypeAddEditFun,
} from "../../store/reducers/metaCrud";
import {
  getUserJobposition,
  getUserListData,
} from "../../store/reducers/user/auth";
import {
  getViolation,
  setFilter,
  violationDeleteFun,
  violationEditFun,
} from "../../store/reducers/violation";
import { getCompany } from "../../store/reducers/company";
import { CheckBox, DateBox, LoadIndicator, RadioGroup } from "devextreme-react";
import { getVehicle } from "../../store/reducers/vehicle";
import { getDriver } from "../../store/reducers/driver";
import BaseHelper from "../../helper/BaseHelper";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Add } from "@mui/icons-material";
const priorityEntities = [
  { id: 0, text: "Тийм" },
  { id: 1, text: "Үгүй" },
];
const Violation = () => {
  const gridRef = useRef();
  const logedUser = useSelector((state) => state.auth.user);
  const violationDataList = useSelector(
    (state) => state.violation.violationData
  );
  const violationServiceTypeCrudList = useSelector(
    (state) => state.metaCrud.violationServiceTypeCrud
  );
  const getViolationCompanyData = useSelector(
    (state) => state.metaCrud.violationCompanyData
  );
  const getViolationCompanyFilter = useSelector(
    (state) => state.metaCrud.violationCompanyFilter
  );
  const violationLegalPenaltyList = useSelector(
    (state) => state.metaCrud.violationLegalPenalty
  );
  const violationDecisionTypeData = useSelector(
    (state) => state.metaCrud.violationDecisionType
  );
  const violationDetectionTypeList = useSelector(
    (state) => state.metaCrud.violationDetectionType
  );
  const violationTypeDataList = useSelector(
    (state) => state.metaCrud.violationTypeData
  );
  const getViolationLegalProvisionData = useSelector(
    (state) => state.metaCrud.violationLegalProvisionData
  );
  const getViolationVehicleData = useSelector(
    (state) => state.metaCrud.violationVehicleData
  );
  const getViolationVehicleFilter = useSelector(
    (state) => state.metaCrud.violationVehicleFilter
  );
  const getViolationOffenderData = useSelector(
    (state) => state.metaCrud.violationOffenderData
  );
  const getVehicleSearchData = useSelector(
    (state) => state.metaCrud.vehicleSearchData
  );
  const userListData = useSelector((state) => state.auth.userListData);
  const driverDataList = useSelector((state) => state.driver.driverData);

  const filterVehicle = useSelector((state) => state.vehicle.filter);
  const filterDriver = useSelector((state) => state.driver.filter);
  const violationCitizenTypeDataList = useSelector(
    (state) => state.metaCrud.violationCitizenTypeData
  );
  const getIsLoading = useSelector((state) => state.metaCrud.isLoading);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [editRowKey, setEditRowKey] = useState(-1);
  const [selectRowIndex, setSelectRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const filterCompany = useSelector((state) => state.company.filter);
  const filter = useSelector((state) => state.violation.filter);
  const start = moment().add(-1, "y");
  const [searchValue, setSearchValue] = useState("");
  const [searchValuePlate, setSearchValuePlate] = useState("");
  const [plateSearchResponse, setPlateSearchResponse] = useState("");
  const [registerSearchResponse, setRegisterSearchResponse] = useState("");
  const [newComp, setNewComp] = useState("");
  const [newCompReg, setNewCompReg] = useState("");
  const [newCompPhone, setNewCompPhone] = useState("");
  const [newCompAddress, setNewCompAddress] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [isCompAdd, setIsCompAdd] = useState(false);
  const [selectionPriority, setSelectionPriority] = useState(
    priorityEntities[0].id
  );
  const dispatch = useDispatch();

  //console.log(filter);
  // console.log(typeof getViolationOffenderData);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
    // dispatch(getViolation(filter));
  };
  const onValueChange = useCallback(
    (e) => {
      console.log("fsdfsdfsdfsdfsdf");
      const previousValue = e.previousValue;
      const newValue = e.value;
      // console.log(newValue);
      dispatch(getViolationOffenderFun({ register: newValue }));
      setSearchValue(newValue);
    },
    [setSearchValue, dispatch]
  );
  // console.log(userListData);
  const onValuePlateChange = useCallback(
    (e) => {
      const previousValue = e.previousValue;
      const newValue = e.value;
      // console.log(newValue);
      dispatch(getVehicleSearchDataFun({ plate_no: newValue }));
      setSearchValuePlate(newValue);
    },
    [setSearchValue, dispatch]
  );
  useEffect(() => {
    if (getVehicleSearchData !== null) {
      setPlateSearchResponse(getVehicleSearchData);
    }
  }, [getVehicleSearchData]);
  useEffect(() => {
    if (getViolationOffenderData !== null) {
      setRegisterSearchResponse(getViolationOffenderData);
    }
  }, [getViolationOffenderData]);

  const addVoilComp = () => {
    // console.log(newCompReg);
    if (
      newCompAddress === "" ||
      newComp === "" ||
      newCompPhone === "" ||
      newCompReg === ""
    ) {
      BaseHelper.nodifyFun("Мэдээлэл гүцэд оруулан уу!!", "error");
    } else {
      var data = {
        address: newCompAddress,
        id: 0,
        name: newComp,
        phone_number: newCompPhone,
        regnum: newCompReg,
      };
      // console.log(newValue);
      dispatch(getViolationCompanyEditFun(data));
      setIsCompAdd(false);
      setNewComp("");
      setNewCompAddress("");
      setNewCompPhone("");
      setNewCompReg();
    }

    // setSearchValuePlate(newValue);
  };

  // const onValueChange = useCallback((v) => {
  //   console.log(v);
  //   const result = v;

  //   setSearchValue(result);
  //   // if (value) {

  //   // }
  //   // console.log(v);
  // }, []);
  ///console.log(getVehicleSearchData);
  useEffect(() => {
    if (userListData == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(
        getUserListData({
          search_value: "",
          user_type_id: 12,
        })
      );
    }
  }, [userListData, dispatch]);
  useEffect(() => {
    if (violationDataList === null && Object.keys(filter).length === 0) {
      dispatch(
        //  getViolation(filter)
        getViolation({
          ...filter,
          start_date: moment().year() + "-" + "01" + "-01",
          end_date: moment().year() + "-" + "12" + "-31",
        })
      );
    } else {
      setFormData(violationDataList);
    }
  }, [violationDataList, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getViolation(filter));
    }
  }, [filter, dispatch]);
  //console.log(moment().year());
  useEffect(() => {
    if (violationServiceTypeCrudList === null) {
      dispatch(getviolationServiceTypeCrudFun());
    }
  }, [violationServiceTypeCrudList, dispatch]);

  useEffect(() => {
    if (Object.keys(getViolationCompanyFilter).length === 0) {
      dispatch(getViolationCompanyCrudFun(getViolationCompanyFilter));
    }
  }, [getViolationCompanyFilter, dispatch]);
  //console.log(Object.keys(getViolationVehicleFilter).length);

  useEffect(() => {
    if (violationLegalPenaltyList === null) {
      dispatch(getViolationLegalPenaltyFun());
    }
  }, [violationLegalPenaltyList, dispatch]);
  useEffect(() => {
    if (violationDecisionTypeData === null) {
      dispatch(getViolationDecisionTypeFun());
    }
  }, [violationDecisionTypeData, dispatch]);

  useEffect(() => {
    if (violationDetectionTypeList === null) {
      dispatch(getViolationDetectionTypeFun());
    }
  }, [violationDetectionTypeList, dispatch]);
  useEffect(() => {
    if (violationTypeDataList === null) {
      dispatch(getViolationTypeDataFun());
    }
  }, [violationTypeDataList, dispatch]);

  useEffect(() => {
    if (
      Object.keys(getViolationVehicleFilter).length === 0 &&
      getViolationVehicleData === null
    ) {
      dispatch(getViolationVehicleCrud(getViolationVehicleFilter));
    }
  }, [getViolationVehicleData, getViolationVehicleFilter, dispatch]);

  // console.log(getViolationVehicleData);
  useEffect(() => {
    if (driverDataList == null) {
      dispatch(getDriver({ ...filterDriver, is_approved: 1 }));
    }
  }, [driverDataList, filterDriver, dispatch]);
  useEffect(() => {
    if (violationCitizenTypeDataList === null) {
      dispatch(getViolationCitizenTypeData());
    }
  }, [violationCitizenTypeDataList, dispatch]);
  useEffect(() => {
    if (getViolationLegalProvisionData === null) {
      dispatch(getViolationLegalProvisionFun());
    }
  }, [getViolationLegalProvisionData, dispatch]);
  // useEffect(() => {
  //   if (getViolationOffenderData === null) {
  //     dispatch(getViolationOffenderFun());
  //   }
  // }, [getViolationOffenderData, dispatch]);
  //console.log(getViolationLegalProvisionData);
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length > 0) {
  //     dispatch(getFeedBack(filter));
  //   }
  // }, [filter, dispatch]);
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowsData[0]);

    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };
  // function deleteRecords() {
  //   gridRef.current.instance.getSelectedRowsData().forEach((key) => {
  //     console.log(key);
  //     //  dispatch(removeCompanyList(key.id));
  //   });
  //   // this.setState({
  //   //   selectedItemKeys: [],
  //   // });
  //   gridRef.current.instance.refresh();
  // }
  const hideInfo = () => {
    setIsCompAdd(false);

    // setFormEditData("");
  };
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;
      //console.log(e.changes[0].type);
      const selectedData = gridRef.current.instance.getSelectedRowsData();
      // console.log(selectedData);
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          // console.log(e.changes);
          const data = e.changes[0].data;

          if (Object.keys(data).length > 0) {
            data["id"] = 0;
            data["offender_id"] =
              getViolationOffenderData !== null
                ? getViolationOffenderData[0].id
                : "";
            data["vehicle_id"] = getVehicleSearchData.id;
            // data["legal_provision_id"] = 1;
            // data["is_active"] = 1;
          }
          dispatch(violationEditFun(data));

          // console.log(data);
          gridRef.current.instance.cancelEditData();
          e.cancel = true;
          // e.component.cancelEditData();
          gridRef.current.instance.cancelEditData();
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;
          //  console.log(data);
          data["id"] = e.changes[0].key;
          data["offender_id"] =
            getViolationOffenderData !== null
              ? getViolationOffenderData[0].id
              : selectedData[0].offender_id;
          data["vehicle_id"] =
            getVehicleSearchData !== null
              ? getVehicleSearchData.id
              : selectedData[0].vehicle_id;
          // data["legal_provision_id"] = 1;
          // data["is_active"] = 1;
          // if ("code" in data === false) {
          //   data["code"] = selectedData[0].code;
          // }
          // if ("rank" in data === false) {
          //   data["rank"] = selectedData[0].rank;
          // }
          // if ("name" in data === false) {
          //   data["name"] = selectedData[0].name;
          // }
          //console.log(data);
          dispatch(violationEditFun(data));
          gridRef.current.instance.cancelEditData();

          e.cancel = true;
        } else if (e.changes[0].type === "remove") {
          // console.log(e.changes[0].key);
          const data = { id: e.changes[0].key };
          // console.log(data);
          dispatch(violationDeleteFun(data));

          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }
      // e.component.cancelEditData();
    },
    [getViolationOffenderData, getVehicleSearchData, dispatch]
  );
  // console.log(gridRef.current.instance.cancelEditData());
  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    // console.log(editRowKey);
    setEditRowKey(editRowKey);
  }, []);

  // function onEditorPreparing(e) {
  //   // treeListRef.current.instance.selectedRowKeys();
  //   console.log(e.row.data);
  //   e.allowEditing === true ? setEditRowData(e.row.data) : setEditRowData(null);
  // }
  const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    let result = moment(newStartDate).diff(newEndDate, "days");
    return result;
    // const startDat = moment(startDate);
    // const timeEn = moment(timeEnd);
    // const diff = timeEn.diff(startDat);
    // const diffDuration = moment.duration(diff);
    // return diffDuration.days();
  };
  //console.log(BaseHelper.permitionCheck(5, 0, "is_create", logedUser));
  // const searchVeh = useCallback(
  //   (e) => {
  //     console.log(searchValue);
  //     if (e.event.type === "dxclick" && searchValue !== "") {
  //       dispatch(getViolationOffenderFun({ register: searchValue }));
  //       // dispatch(getVehicleInspectionData(plateValue));
  //     }
  //   },
  //   [searchValue, dispatch]
  // );
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  // const searchButton = {
  // const searchButton =
  //   (() => ({
  //     // icon: "/assets/eye.png",
  //     //stylingMode: "outlined",
  //     icon: "eyeopen",
  //     stylingMode: "text",
  //     //icon: "fa fa-envelope-o",
  //     type: "default",
  //     //disabled: searchValue === "" ? true : false,
  //     onClick: (e) => {
  //       console.log(searchValue);
  //       dispatch(getViolationOffenderFun({ register: searchValue }));
  //       // searchVeh(e);
  //       // dispatch(getVehicleSearch(plateValue));
  //       // dispatch(getVehicleInspectionData(plateValue));
  //     },
  //   }),
  //   [dispatch, searchValue]);
  const searchButton = useMemo(
    (e) => ({
      icon: "/assets/eye.png",
      // text: "хайх",
      //  stylingMode: "text",
      stylingMode: "contained",
      disabled: getIsLoading ? true : false,
      onClick: (e) => {
        // console.log(e.component.option("text"));
        // dispatch(getViolationOffenderFun({ register: searchValue }));
      },
    }),
    [getIsLoading]
  );
  const searchPlateButton = useMemo(
    (e) => ({
      icon: "/assets/eye.png",
      // text: "хайх",
      //  stylingMode: "text",
      stylingMode: "contained",
      disabled: getIsLoading ? true : false,
      onClick: (e) => {
        // console.log(e.component.option("text"));
        // dispatch(getViolationOffenderFun({ register: searchValue }));
      },
    }),
    [getIsLoading]
  );
  // const onValueChecked = useCallback((args) => {
  //   console.log(args.value);
  //   setCheckBoxValue(args.value);
  // }, []);
  const onValueChecked = useCallback(
    (e) => {
      setSelectionPriority(e.value);
      //  console.log(e.value);
      if (e.value === 0) {
        setCheckBoxValue(true);
      } else {
        setCheckBoxValue(false);
      }
    },
    [setSelectionPriority]
  );
  return (
    <Box m="20px">
      <Header title="Зөрчил" subtitle="Зөрчил жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    dispatch(getViolation(filter));
                    gridRef.current.instance.refresh();
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={
                    BaseHelper.permitionCheck(5, 0, "is_create", logedUser) ===
                    false
                      ? true
                      : false
                  }
                  icon={"add"}
                  onClick={() => {
                    gridRef.current.instance.addRow();
                  }}
                  type={"default"}
                  text="Шинэ"
                />
              </Box>
            </Item>
            <Item location="before">
              <FilterBox getFilters={setFilters} />
            </Item>
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"edit"}
                  onClick={(e) => {
                    // if (selectIndex > -1) {
                    //   gridRef.current.instance.editRow(selectIndex);
                    // } else {
                    //   console.log("ffff");
                    // }
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //  console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      gridRef.current.instance.editRow(selectRowIndex[0] - 1);
                    } else {
                      notify(
                        {
                          message: "Засах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    //gridRef.current.instance.instance.deselectAll();
                  }}
                  type={"success"}
                  text="Засах"
                />
              </Box>
            </Item> */}
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={() => { 
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      for (let i = 0; i < getSelectedData.length; i++) {
                        const element = getSelectedData[i];

                        console.log(element);
                      }
                    } else {
                      notify(
                        {
                          message: "Устгах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item> */}
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
            rowAlternationEnabled={true}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={520}
            wordWrapEnabled={true}
            onRowDblClick={(row) => {
              setFormEditData(row.data);
              // console.log(row);
              dispatch(
                getVehicleSearchDataFun({ plate_no: row.data.plate_no })
              );

              setSearchValuePlate(row.data.plate_no);
              dispatch(
                getViolationOffenderFun({
                  register: row.data.offender_register,
                })
              );
              setSearchValue(row.data.offender_register);
              gridRef.current.instance.editRow(row.rowIndex);
              //   const start = moment().add(-4, "m");
              //  console.log(dateConverter(Date.now(), "2023-10-01"));
            }}
            //   defaultSelectionFilter={selectionFilter}
            onEditCanceled={(e) => {
              // console.log(e);
              setSearchValue("");
              setSearchValuePlate("");
              setPlateSearchResponse("");
              setRegisterSearchResponse("");
            }}
            onSelectionChanged={onSelectionChanged}
            onSaving={onSaving}
            // onRowPrepared={(e) => {
            //   // console.log(e.data ? e.data.violation_date : "");
            //   if (e.rowType === "data") {
            //     const date = dateConverter(Date.now(), e.data.violation_date);
            //     console.log(date);
            //     if (date >= 30) {
            //       e.rowElement.style.backgroundColor = "red";
            //       // console.log(date);
            //     } else if (date > 23 && date < 30) {
            //       //  console.log(date);
            //       e.rowElement.style.backgroundColor = "orange";
            //     }
            //   } else {
            //   }
            // }}
            onCellPrepared={(e) => {
              // console.log(e.data ? e.data.violation_date : "");
              if (
                e.rowType === "data" &&
                e.column.dataField === "violation_date"
              ) {
                const date = dateConverter(Date.now(), e.data.violation_date);
                //  console.log({ date });
                if (e.row.data.decision_type_id === 2 && date < 15) {
                  e.cellElement.style.backgroundColor = "pink";
                  //  e.rowElement.style.backgroundColor = "red";
                  // console.log(date);
                } else if (e.row.data.decision_type_id === 2 && date > 14) {
                  e.cellElement.style.backgroundColor = "red";
                  //   e.rowElement.style.backgroundColor = "orange";
                  // e.cellElement.style.backgroundColor = "orange";
                } else if (e.row.data.decision_type_id === 10 && date <= 0) {
                  e.cellElement.style.backgroundColor = "#00c3ff";
                  // console.log(-3 > -4);
                  // console.log(date);
                  //   e.rowElement.style.backgroundColor = "orange";
                  // e.cellElement.style.backgroundColor = "orange";
                } else if (
                  e.row.data.decision_type_id === 10 &&
                  date >= 3 &&
                  date <= 5
                ) {
                  e.cellElement.style.backgroundColor = "orange";
                  //   e.rowElement.style.backgroundColor = "orange";
                  // e.cellElement.style.backgroundColor = "orange";
                } else if (e.row.data.decision_type_id === 10 && date > 5) {
                  e.cellElement.style.backgroundColor = "red";
                  //   e.rowElement.style.backgroundColor = "orange";
                  // e.cellElement.style.backgroundColor = "orange";
                } else {
                }
              }
            }}
            // onEditorPreparing={onEditorPreparing}
          >
            <Editing
              mode="popup"
              allowUpdating={BaseHelper.permitionCheck(
                5,
                0,
                "is_update",
                logedUser
              )}
              //  allowAdding={true}
              allowDeleting={BaseHelper.permitionCheck(
                5,
                0,
                "is_delete",
                logedUser
              )}
              // changes={changes}
              useIcons={true}
              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popup
                title="Зөрчил бүртгэх"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "80%"}
              />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                saveRowToNode={"ffff"}
                cancelRowChanges={"Цуцлах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="plate_no" />
                  <Item dataField="garage_no" />

                  <Item dataField="offender_register" />

                  <Item dataField="mark" />

                  <Item dataField="offender_firstname" />
                  <Item dataField="violation_date" />
                  <Item dataField="checked_by" />
                </Item>
                <Item
                  itemType="group"
                  caption="Зөрчил"
                  colCount={1}
                  colSpan={2}
                >
                  <Item dataField="number" />
                  <Item dataField="service_type_id" />
                  <Item dataField="isComp" />
                  <Item itemType="group" colCount={5}>
                    <Item
                      dataField="company_id"
                      colSpan={4}
                      // disabled={true}
                    />
                    <Item>
                      <Button
                        disabled={checkBoxValue ? false : true}
                        icon="plus"
                        onClick={() => {
                          setIsCompAdd(true);
                        }}
                      />
                    </Item>
                  </Item>
                  <Item dataField="legal_penalty_id" />

                  <Item dataField="legal_provision_id" />
                  <Item dataField="decision_type_id" />

                  <Item dataField="detection_type_id" />
                  <Item dataField="type_id" />
                  <Item dataField="citizen_type_id" />
                  <Item dataField="address" />
                </Item>
                <Item
                  itemType="group"
                  caption="Поркурор"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="court_number" />
                  <Item dataField="prosecutor_name" />
                  <Item dataField="court_checked_date" />
                  <Item dataField="court_recieved_date" />
                  <Item dataField="court_presented_date" />
                  <Item dataField="court_reviewed_date" />
                  <Item dataField="court_decision" />
                </Item>
              </Form>
            </Editing>

            <FilterRow visible={true} />
            <Column alignment={"center"} Chooser enabled={false} />

            <Column
              alignment={"center"}
              colSpan="2"
              // caption={"№"}
              fixedPosition="left"
              format="fixedPoint"
              fixed={true}
            >
              <Column
                alignment={"center"}
                allowSorting={true}
                caption="Д/Д"
                fixed={true}
                dataField={"id"}
                // cellRender={(e) => {
                //   return e.rowIndex + 1;
                // }}
              />
            </Column>
            <Column
              alignment={"center"}
              caption={"Зөрчил үйлдсэн хүн, хуулийн этгээд"}
            >
              <Column
                alignment={"center"}
                dataField="offender_lastname"
                caption={"Овог, нэр"}
                width={120}
                cellRender={(v) => {
                  // console.log(v);
                  return v.value !== null
                    ? v.value.substring(0, 1) + "." + v.data.offender_firstname
                    : v.data.offender_firstname;
                }}
              />
              {/* <Column  alignment={"center"}
                dataField="offender_register"
                caption={"Регистрийн дугаар "}
              /> */}

              <Column
                alignment={"center"}
                allowEditing={true}
                dataField="offender_register"
                caption="Регистрийн дугаар"
                width={120}
                editCellRender={(e) => {
                  //   console.log(e.value);
                  return (
                    <TextBox
                      location="before"
                      value={searchValue === "" ? e.value : searchValue}
                      // value={
                      //   searchValue !== ""
                      //     ? getViolationOffenderData !== null
                      //       ? getViolationOffenderData[0].register
                      //       : ""
                      //     : e.value
                      // }
                      // onKeyUp={onValueChange}

                      onValueChanged={onValueChange}
                      //onValueChange={onValueChange}
                    >
                      <TextBoxButton
                        name="password"
                        location="after"
                        options={searchButton}
                      >
                        <LoadIndicator className="button-indicator" />
                      </TextBoxButton>
                    </TextBox>
                  );
                }}
              />
              <Column
                alignment={"center"}
                dataField="isComp"
                visible={false}
                //  hidingPriority={1}
                editCellRender={(e) => {
                  return (
                    // <CheckBox
                    //   value={
                    //     getViolationCompanyData !== null && checkBoxValue
                    //       ? true
                    //       : false
                    //   }
                    //   text={checkBoxValue ? "Тийм" : "Үгүй"}
                    //   onValueChanged={onValueChecked}
                    // />
                    <RadioGroup
                      layout="horizontal"
                      id="radio-group-with-selection"
                      items={priorityEntities}
                      value={selectionPriority}
                      defaultValue={priorityEntities[0]}
                      valueExpr="id"
                      displayExpr="text"
                      onValueChanged={onValueChecked}
                    />
                  );
                  // console.log(e.value);
                }}
                // editCellRender={cellEditRenderFun}
                caption={"Харьяалах ААНБ сонгох эсэх"}
              />
              <Column
                alignment={"center"}
                dataField="company_id"
                width={120}
                allowEditing={checkBoxValue ? true : false}
                caption="Харьяалах ААНБ"
              >
                {/* <RequiredRule /> */}
                <Lookup
                  dataSource={getViolationCompanyData}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column
                alignment={"center"}
                dataField="legal_penalty_id"
                caption="Зөрчигдсөн хуулийн заалт"
                width={220}
              >
                {/* <RequiredRule /> */}
                <Lookup
                  dataSource={violationLegalPenaltyList}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
            </Column>
            <Column
              alignment={"center"}
              caption={"Зөрчил үйлдсэн хугацаа, шийтгэлийн дугаар "}
              dataField={"number"}
            />
            <Column
              alignment={"center"}
              //  legal_provision_id
              dataField="legal_provision_id"
              caption=" Зөрчлийн тухай хуулийн зүйл, хэсэг"
              width={220}
            >
              <RequiredRule />
              <Lookup
                dataSource={getViolationLegalProvisionData}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column
              alignment={"center"}
              dataField="legal_penalty_amount"
              caption={"Оногдуулсан шийтгэл"}
            />
            <Column
              alignment={"center"}
              dataField="checked_by"
              caption={"Зөрчлийн шалган шийдвэрлэсэн хүний албан тушаал, нэр"}
            >
              <RequiredRule />
              <Lookup
                dataSource={userListData}
                valueExpr="id"
                displayExpr="firstname"
              />
            </Column>
            <Column
              alignment={"center"}
              dataField="detection_type_id"
              //value={10}
              caption="Илрүүлэлт"
            >
              <RequiredRule />
              <Lookup
                //defaultValue={violationDetectionTypeList[0].id}
                dataSource={violationDetectionTypeList}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column alignment={"center"} dataField="type_id" caption="Зөрчил">
              <RequiredRule />
              <Lookup
                dataSource={violationTypeDataList}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>

            <Column
              alignment={"center"}
              dataField="citizen_type_id"
              caption="Хүн/ААН"
            >
              <RequiredRule />
              <Lookup
                dataSource={violationCitizenTypeDataList}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column
              alignment={"center"}
              dataField="violation_date"
              format="yyyy-MM-dd"
              dataType="date"
              caption={"Огноо"}
            />
            <Column
              alignment={"center"}
              dataField="service_type_id"
              caption="Үйлчилгээ"
              width={125}
            >
              <RequiredRule />
              <Lookup
                dataSource={violationServiceTypeCrudList}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column
              alignment={"center"}
              dataField="decision_type_id"
              caption="Хэрэг"
              //   value={9}
              width={125}
            >
              <RequiredRule />
              <Lookup
                dataSource={violationDecisionTypeData}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column alignment={"center"} caption={"Удирдамжийн нэр"} />
            <Column
              alignment={"center"}
              caption={"Грааш дугаар"}
              dataField={"garage_no"}
            />
            <Column
              alignment={"center"}
              dataField="plate_no"
              caption="Улсын дугаар"
              width={125}
              editCellRender={(e) => {
                // setSearchValuePlate(e.value);

                return (
                  <TextBox
                    location="before"
                    value={searchValuePlate === "" ? e.value : searchValuePlate}
                    // value={
                    //   searchValue !== ""
                    //     ? getViolationOffenderData !== null
                    //       ? getViolationOffenderData[0].register
                    //       : ""
                    //     : e.value
                    // }
                    // onKeyUp={onValueChange}

                    onValueChanged={onValuePlateChange}
                    //onValueChange={onValuePlateChange}
                  >
                    <TextBoxButton
                      name="password"
                      location="after"
                      options={searchPlateButton}
                    >
                      <LoadIndicator className="button-indicator" />
                    </TextBoxButton>
                  </TextBox>
                );
              }}
            />
            <Column alignment={"center"} dataField="address" caption={"Хаяг"}>
              <RequiredRule />
            </Column>
            <Column
              alignment={"center"}
              dataField="prosecutor_name"
              caption={"Прокурорын нэр"}
            />
            <Column
              alignment={"center"}
              dataField="court_number"
              caption="Бүртгэлийн дугаар"
            />
            <Column
              alignment={"center"}
              dataField="court_checked_date"
              caption={"Шалгасан хоног"}
              editorOptions={{
                mode: "number",
              }}
            />
            <Column
              alignment={"center"}
              dataField="court_recieved_date"
              editorOptions={{
                mode: "date",
              }}
              caption={"Гомдол хүлээн авсан өдөр"}
            />
            <Column
              alignment={"center"}
              dataField="court_presented_date"
              caption={"Прокурорт танилцуулсан өдөр"}
              editorOptions={{
                mode: "date",
              }}
            />

            <Column
              alignment={"center"}
              caption={"гомдол гаргагчийн талаарх мэдээлэл"}
            />
            <Column
              alignment={"center"}
              dataField="court_reviewed_date"
              caption={"Прокурор хянасан өдөр"}
              editorOptions={{
                mode: "date",
              }}
            />
            <Column alignment={"center"} caption={"ЭБАТ"}>
              <Column alignment={"center"} caption={"Зөрчлийн хэрэг нээсэн"} />
            </Column>
            <Column
              alignment={"center"}
              caption={"Зөрчлийн хэргийг прокурор хянаад"}
            >
              <Column
                alignment={"center"}
                caption={"Зөрчлийн хэргийн дугаар олгосон"}
              />
              <Column
                alignment={"center"}
                caption={"Харьяаллын дагуу шилжүүлсэн"}
              />
              <Column
                alignment={"center"}
                caption={
                  "Эрх бүхий албан тушаалтны шийдвэрийг хүчингүй болгосон"
                }
              />
            </Column>
            <Column alignment={"center"} caption={"дээд шатны прокурор"}>
              <Column
                alignment={"center"}
                caption={"Шийдвэрийг хэвээр үлдээсэн"}
              />
              <Column
                alignment={"center"}
                caption={"Шийдвэрийг хүчингүй болгосон"}
              />
              <Column
                alignment={"center"}
                caption={"Шийдвэрт өөрчлөлт оруулсан"}
              />
            </Column>

            <Column
              width={220}
              caption={
                "Прокурор ЗШШТХуулийн 1.12 дугаар зүйлийн 6 дахь хэсэгт зааснаар эрх бүхий албан тушаалтны шийдвэрийн биелэлтийг түдгэлзүүлсэн он, сар, өдөр, шийдвэрийн дугаар"
              }
            />
            <Column caption={"ЭБАТ татгалзсан"} />
            <Column caption={"ЭБАТ харъяаллын дагуу шилжүүлсэн"} />
            <Column caption={"ӨРШӨӨЛ"} />
            <Column caption={"АРХИВ КОД"} />
            {/* <Column  alignment={"center"}
              caption={
                "Прокурор ЗШШТХуулийн 1.12 дугаар зүйлийн 6 дахь хэсэгт зааснаар эрх бүхий албан тушаалтны шийдвэрийн биелэлтийг түдгэлзүүлсэн он, сар, өдөр, шийдвэрийн дугаар"
              }
            /> */}
            {/* <Column
              alignment={"center"}
              dataField="offender_firstname"
              // hidingPriority={1}
              visible={false}
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={
                      registerSearchResponse !== ""
                        ? registerSearchResponse[0].lastname +
                          " " +
                          registerSearchResponse[0].firstname
                        : ""
                    }
                    valueChangeEvent="input"
                  />
                );
                // console.log(e.value);
              }}
              // editCellRender={cellEditRenderFun}
              caption={"Нэр"}
            /> */}
            {/* <Column
              alignment={"center"}
              dataField="mark"
              visible={false}
              // hidingPriority={0}
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={
                      plateSearchResponse !== ""
                        ? plateSearchResponse.mark_name
                        : ""
                    }
                    valueChangeEvent="input"
                  />
                );
                // console.log(e.value);
              }}
              // editCellRender={cellEditRenderFun}
              caption={"Марк, Загвар"}
            /> */}

            {/* <Column
              alignment={"center"}
              width={120}
              dataType="date"
              dataField="violation_date"
              caption={"Зөрчил үйлдсэн хугацаа"}
            >
              {" "}
              <RequiredRule />
            </Column> */}

            {/* <Column  alignment={"center"}
              dataField="legal_provision_name"
              caption={"Зөрчлийн тухай хуулийн зүйл, хэсэг"}
            /> */}

            {/* <Column
              alignment={"center"}
              width={150}
              dataField="detected_by"
              caption={"Зөрчлийг илрүүлсэн"}
            >
              <RequiredRule />
              <Lookup
                dataSource={userListData}
                valueExpr="id"
                displayExpr="firstname"
              />
            </Column>

            <Column
              alignment={"center"}
              dataField="court_decision"
              caption={"Шүүхийн шийдвэр"}
            /> */}

            {/* <Column  alignment={"center"}
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Огноо"}
            /> */}

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
          <Modal
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isCompAdd}
            showTitle={true}
            title="Зөрчил үйлдсэн ААНБ бүртгэх"
            onHiding={hideInfo}
            width={"60%"}
            height={400}
          >
            <Stack direction={"column"} justifyContent={"start"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start", mb: 1 }}>
                    Нэр
                  </Typography>
                  <TextBox
                    value={newComp}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setNewComp(e.event.target.value);
                    }}
                    placeholder="Аж ахуй нэгжийн нэр."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start", mb: 1 }}>
                    Регистер
                  </Typography>
                  <TextBox
                    value={newCompReg}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setNewCompReg(e.event.target.value);
                    }}
                    placeholder="Аж ахуй нэгжийн регистер."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start", mb: 1 }}>
                    Утас
                  </Typography>
                  <TextBox
                    value={newCompPhone}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setNewCompPhone(e.event.target.value);
                    }}
                    placeholder="Аж ахуй нэгжийн утас."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start", mb: 1 }}>
                    Хаяг
                  </Typography>
                  <TextBox
                    value={newCompAddress}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setNewCompAddress(e.event.target.value);
                    }}
                    placeholder="Аж ахуй нэгжийн хаяг."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 4,
                height: "45px",
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <Button
                  width={120}
                  text="Хаах"
                  type="normal"
                  stylingMode="contained"
                  onClick={hideInfo}
                />
                <Button
                  width={120}
                  text="Бүртгэх"
                  type="normal"
                  stylingMode="contained"
                  onClick={addVoilComp}
                />
              </Stack>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default Violation;
