import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import dark from "@amcharts/amcharts5/themes/Dark";

function FeedBackDashboard(props) {
  const [data, setData] = useState([
    {
      serial_key: "2023-05",
      type1: 13,
      type2: 4,
      type3: 6,
      type4: 0,
    },
    {
      serial_key: "2023-06",
      type1: 10,
      type2: 3,
      type3: 3,
      type4: 6,
    },
  ]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.grievance.line_chart.data);
    }
  }, [props]);
  // console.log(props);
  //console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
      })
    );

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);
    // let data = [
    //   {
    //     serial_key: "2023-05",
    //     type1: 13,
    //     type2: 4,
    //     type3: 6,
    //     type4: 0,
    //   },
    //   {
    //     serial_key: "2023-06",
    //     type1: 10,
    //     type2: 3,
    //     type3: 3,
    //     type4: 6,
    //   },
    //   {
    //     serial_key: "2023-07",
    //     type1: 9,
    //     type2: 9,
    //     type3: 1,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2023-09",
    //     type1: 3,
    //     type2: 9,
    //     type3: 5,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2023-10",
    //     type1: 9,
    //     type2: 4,
    //     type3: 1,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2023-11",
    //     type1: 9,
    //     type2: 1,
    //     type3: 1,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-01",
    //     type1: 9,
    //     type2: 9,
    //     type3: 2,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-02",
    //     type1: 1,
    //     type2: 1,
    //     type3: 1,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-03",
    //     type1: 9,
    //     type2: 4,
    //     type3: 4,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-04",
    //     type1: 3,
    //     type2: 9,
    //     type3: 1,
    //     type4: 1,
    //   },
    //   {
    //     serial_key: "2024-05",
    //     type1: 3,
    //     type2: 6,
    //     type3: 8,
    //     type4: 1,
    //   },
    //   {
    //     serial_key: "2024-06",
    //     type1: 9,
    //     type2: 9,
    //     type3: 4,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-07",
    //     type1: 1,
    //     type2: 8,
    //     type3: 9,
    //     type4: 9,
    //   },
    //   {
    //     serial_key: "2024-09",
    //     type1: 9,
    //     type2: 1,
    //     type3: 9,
    //     type4: 9,
    //   },
    // ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "serial_key",
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true,
          minGridDistance: 70,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);
    //  console.log(data.grievance.line_chart);
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
        }),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(name, field) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: true,
          valueYField: field,
          categoryXField: "serial_key",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
          }),
        })
      );

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });
      if (data.length > 2) {
        series.data.setAll(props.dashboardData.grievance.line_chart.data);
        series.appear(1000);
      } else {
      }
    }

    createSeries("Гомдол", "type1");
    createSeries("Санал хүсэлт", "type2");
    createSeries("Талархал", "type3");
    createSeries("Мэдээлэл", "type4");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set(
    //   "scrollbarX",
    //   am5.Scrollbar.new(root, {
    //     orientation: "horizontal",
    //   })
    // );

    // Create axis ranges
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/axis-ranges/
    // let rangeDataItem = xAxis.makeDataItem({
    //   category: "2001",
    //   endCategory: "2003",
    // });

    //let range = xAxis.createAxisRange(rangeDataItem);

    // rangeDataItem.get("grid").setAll({
    //   stroke: am5.color(0x00ff33),
    //   strokeOpacity: 0.5,
    //   strokeDasharray: [3],
    // });

    // rangeDataItem.get("axisFill").setAll({
    //   fill: am5.color(0x00ff33),
    //   fillOpacity: 0.1,
    //   visible: false,
    // });

    // rangeDataItem.get("label").setAll({
    //   inside: true,
    //   text: "Fines for speeding increased",
    //   rotation: 90,
    //   centerX: am5.p100,
    //   centerY: am5.p100,
    //   location: 0,
    //   paddingBottom: 10,
    //   paddingRight: 15,
    // });

    // let rangeDataItem2 = xAxis.makeDataItem({
    //   category: "2007",
    // });

    // let range2 = xAxis.createAxisRange(rangeDataItem2);

    // rangeDataItem2.get("grid").setAll({
    //   stroke: am5.color(0x00ff33),
    //   strokeOpacity: 1,
    //   strokeDasharray: [3],
    // });

    // rangeDataItem2.get("axisFill").setAll({
    //   fill: am5.color(0x00ff33),
    //   fillOpacity: 0.1,
    //   visible: true,
    // });

    // rangeDataItem2.get("label").setAll({
    //   inside: true,
    //   text: "Motorcycle fee introduced",
    //   rotation: 90,
    //   centerX: am5.p100,
    //   centerY: am5.p100,
    //   location: 0,
    //   paddingBottom: 10,
    //   paddingRight: 15,
    // });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, props]);

  return <div id="chartdiv" style={{ width: "100%", height: "380px" }}></div>;
}
export default FeedBackDashboard;
