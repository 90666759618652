import { Box, IconButton, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import ReactPlayer from "react-player";
import { border } from "polished";
import { grey } from "@mui/material/colors";
import { FaFilePdf } from "react-icons/fa";
import { Colors } from "../../styles/theme";
import { Document, PDFDownloadLink, Page, Text } from "@react-pdf/renderer";
const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const pdfUrl = "../../../file/zaavar.pdf";

  // Function to trigger the download
  const onButtonClick = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  return (
    <Box m="20px">
      <Header title="Заавар" subtitle="Програм ашиглах заавар" />
      <Box sx={{ textAlign: "right", p: 1 }}>
        <IconButton
          onClick={() => {
            onButtonClick(pdfUrl);
          }}
        >
          <FaFilePdf color={Colors.danger} />
        </IconButton>
        {/* <Typography>
          {" "}

          <FaFilePdf /> татах
        </Typography> */}
      </Box>
      {/* <PDFDownloadLink
        document={
          <Document>
            <Page>
              <Text>Toto</Text>
            </Page>
          </Document>
        }
        fileName={pdfUrl}
      >
        Download
      </PDFDownloadLink> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            1. Хүний нөөцийн цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/hunii_nuuts.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Хүний нөөцийн модуль видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            2. Хэлтэс, алба бүртгэх
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/heltes.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Хэлтэс, алба хэрхэн бүртгэх видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            3. Зөрчлийн цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/zurchil.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Зөрчлийн модуль видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            4. Жолоочийн цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/jolooch.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Жолооч бүртгэх модуль видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            5. Тээврийн хэрэгсэл цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/teevriin heregsel.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Тээврийн хэрэгсэл видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            6. Тээврийн хэрэгсэл ирсэн хүсэлт цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/teevriin heregseld irsen huselt.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Тээврийн хэрэгсэл ирсэн хүсэлт видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            7. Жолооч ирсэн хүсэлт цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/jolooch_irsen_huselt.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Жолооч бүртгүүлэхээр ирүүлсэн хүсэлтүүд видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            8. Мета дата цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/metadata.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Мета дата видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            9. Тайлан цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/tailan.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Зөрчлийн тайлан видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            10. Тохиргоо цэс
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url="../../../video/tohirgoo.mp4"
              width="100%"
              height="500px"
            />
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Зөрчлийн тайлан видео заавар.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
