// eslint-disable-next-line import/no-anonymous-default-export
export default {
  showBorders: true,
  //columnChooser: { enabled: true },
  hoverStateEnabled: true,
  remoteOperations: true,
  columnAutoWidth: true,
  allowColumnReordering: true,
  // scrolling: {
  //   columnRenderingMode: "virtual",
  // },
  height: 600,
  columnChooser: {
    enabled: false,
    title: "Багана тохируулах",
    mode: "select",
  },
  columnFixing: { enabled: true },
  sorting: {
    ascendingText: "Өсөхөөр эрэмблэх",
    descendingText: "Буурхаар эрэмблэх",
    clearText: "Эрэмбийг болиулах",
  },
  allowColumnResizing: true,
  noDataText: "Мэдээлэл бүртгэгдээгүй байна",
  selection: { mode: "multiple", selectAllMode: "page" },
  showColumnLines: true,
  showRowLines: true,
  rowAlternationEnabled: true,
  allowColumnResizing: true,
  columnAutoWidth: true,
  paging: {
    visible: true,
    defaultPageSize: 50,

    defaultPageIndex: 0,
  },
  pager: {
    visible: true,
    showInfo: true,
    showPageSizeSelector: true,
    allowedPageSizes: [50, 100, 200, 300, 1000],
    infoText: "НИЙТ {1} ХУУДАС (НИЙТ {2} БИЧЛЭГ)",
    showNavigationButtons: "full",
  },
  loadPanel: {
    text: "Уншиж байна түр хүлээнэ үү",
    showPane: true,
    shading: true,
    shadingColor: "rgba(0,0,0,0.4)",
  },

  filterRow: {
    visible: true,
    betweenEndText: "Дуусах",
    betweenStartText: "Эхлэх",
    operationDescriptions: {
      between: "Хооронд",
      contains: "Багтсан тэкст",
      notContains: "Агуулагдаагүй тэкст",
      equal: "Тэнцүү",
      notEqual: "Ялгаатай",
      lessThan: "Бага",
      lessThanOrEqual: "Бага буюу тэнцүү",
      startsWith: "Эхлэх",
      endsWith: "Дуусах",
      greaterThan: "Их",
      greaterThanOrEqual: "Их буюу тэнцүү",
    },
    resetOperationText: "Цэвэрлэх",
  },
  // searchPanel: {
  //   visible: true,
  //   searchVisibleColumnsOnly: true,
  //   placeholder: "Хайлт",
  // },
};
